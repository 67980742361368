import React, { useEffect, useState, useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import "./App.css";
import { IconButton } from "@material-ui/core";
import Succes from "./succes";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "50%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  leftGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: theme.spacing(2), // Adjust as needed
  },
  rightGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Home = () => {
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [isVisable, setIsVisable] = useState(true);
  const [isVisable1, setIsVisable1] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("user_token");
  const name = queryParameters.get("name");
  const [errors, setErrors] = useState({});
  const classes = useStyles();

  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(null);

  const handleVerification = useCallback(async (token) => {
    try {
      let body = {
        user_token: token,
      };

      const res = await axios.post(
        "https://api.codenomad.biz/verify_link",
        body
      );

      if (res.data.code === "200") {
        console.log("API response:", res);
      } else {
        setIsVerified(true);
        // setErrorMsg(res.data.message)
        navigate('/unauthorized',{state :{ code : res.data.code,msg: res.data.message}})
      }
    } catch (error) {
      console.error("API error:", error);
      setError(error.message || "An error occurred");
    }
  }, []);

  useEffect(() => {
    if (token) {
      console.log("#############")
      handleVerification(token);
    } else {
      setIsVerified(true);
      navigate('/unauthorized',{state :{ code : '',msg: 'Welcome to My Beautiful App!.'}})
      // console.log("Token not found in the URL");
    }
  }, [handleVerification]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOnBlue = (event) => {
    // Handle onBlur events
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();

    // Reset errors
    setErrors({});

    // Validation
    let validationErrors = {};

    // Strong password criteria
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\s])[A-Za-z\d@$!%*?&\s]{8,}$/;


    if (!formData.newPassword.trim()) {
      validationErrors.newPassword = "New password is required";
    } else if (!strongPasswordRegex.test(formData.newPassword)) {
      validationErrors.newPassword =
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character";
    }

    if (!formData.confirmPassword.trim()) {
      validationErrors.confirmPassword = "Confirm password is required";
    } else if (formData.confirmPassword !== formData.newPassword) {
      validationErrors.confirmPassword = "Passwords do not match";
    }

    // If there are validation errors, set them in the state
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Continue with your password reset logic
      setLoading(true);
      let body = {
        password: formData.newPassword,
        user_token: token,
      };

      axios
        .post(`https://api.codenomad.biz/setpassword`, body)
        .then((res) => {
          if (res.data.code === "200") {
            setLoading(false);
            setFormData({ newPassword: "", confirmPassword: "" });
            // Use state variable for success message and display it in your component
            // setSuccessMessage("Password reset successful");
            // Or navigate to the success page
            navigate("/success");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("API error:", error);
          navigate('/unauthorized',{state :{ code :'500',msg: 'Request failed with status code 500'}})
          // Handle API error, set error state variable, and display it in your component
        });
    }
  };

  return (
    <Container
      // component="main"
      maxWidth="xl"
    >
      <div className='paper' style={{ width: "90%",display:'flex',alignSelf:'center',justifyContent:'center', margin:"auto",height:"93vh",borderRadius:20 }}>
        {/* {
         token ? "" : <Alert variant="filled" severity="success">
            This is a filled success Alert.
          </Alert>
        } */}
        <Grid container>
          {/* Left Grid - Image */}
          <Grid
            item
            xs={12}
            sm={6}
            className="leftSide"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:"#de2027",
              borderTopLeftRadius:20,
              borderBottomLeftRadius:20
            }}
          >
            <img
              src="/Group3.png"
              alt="Your Image"
              style={{
                width: "70%", // Adjust as needed
                height: "auto",
                // alignSelf: "center",
              }}
            />
          </Grid>

          {/* Right Grid - Form */}
          <Grid
            item
            xs={12}
            sm={6}
            className="rightSide"
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              height: "90vh",
              width: "60%",
              margin: "auto",
              paddingLeft: 60,
              backgroundColor:'#ffffff',
              borderTopRightRadius:20,
              borderBottomRightRadius:20
            }}
          >
               <img
              src="/image.png"
              alt="Your Image"
              style={{
                width: "70%", // Adjust as needed
                height: "auto",
                alignSelf: "center",
              }}
            />
            <Typography
              component="h3"
              variant="h5"
              style={{
                color: "#000000",
                fontSize: 40,
                fontWeight: "revert-layer",
                paddingTop:70
              }}
            >
              Update Password
            </Typography>
            <Typography
              variant="h5"
              style={{ color: "#000000", fontSize: 18, paddingTop: 20 }}
            >
              Hi, {name}
            </Typography>
            {/* <Typography
              variant="h5"
              style={{ color: "#000000", fontSize: 16, paddingTop: 20 }}
            >
              Enter a new password below to Update your password.
            </Typography> */}
            <form className='formClass' noValidate onSubmit={handleOnSubmit}>
              <TextField
                className={classes.inputs}
                variant="outlined"
                type={isVisable ? "password" : "text"}
                margin="normal"
                required
                fullWidth
                id="newpassword"
                label="New Password"
                name="newPassword"
                autoComplete="password"
                autoFocus
                onChange={handleChange}
                onBlur={handleOnBlue}
                value={formData.newPassword}
                error={!!errors.newPassword}
                helperText={errors.newPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setIsVisable(!isVisable)}>
                        {!isVisable ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                type={isVisable1 ? "password" : "text"}
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                autoComplete="email"
                value={formData.confirmPassword}
                // autoFocus
                onChange={handleChange}
                onBlur={handleOnBlue}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setIsVisable1(!isVisable1)}>
                        {!isVisable1 ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  backgroundColor:"#de2027",
                  color: "#ffffff",
                  height: 50,
                }}
                className={classes.submit}
              >
                {loading ? (
                  // If loading is true, show the CircularProgress component
                  <CircularProgress size={24} style={{ color: "#ffffff" }} />
                ) : (
                  // If loading is false, show the button text ("Reset" in this case)
                  "Update"
                )}
              </Button>
            </form>
          </Grid>
        </Grid>
      </div>

      {/* <Succes/> */}
    </Container>
  );
};

export default Home;
