import React, {useEffect, useState } from "react";

import Alert from "@mui/material/Alert";
import axios from "axios";
import "./App.css";
import { IconButton } from "@material-ui/core";
import Success from "./succes";
import Home from "./home";
import WelcomePage from "./welcome";
import ForgotPassword from "./forgotPassword";
import Auth from "./autherror";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import PrivacyPolicyPage from "./policy";
const App = () => {

  return (
    <BrowserRouter>
		<Routes>
		  <Route path="/" element={<Home />} />
			<Route path="success" element={<Success />} />
          <Route path="unauthorized" element={<Auth/>} />
          <Route path="policy" element={<PrivacyPolicyPage/>} />
          <Route path="/forgotPassword" element={<ForgotPassword/>} />

		</Routes>
	  </BrowserRouter>
  );
};

export default App;
