import React,{useEffect} from 'react'
import "./App.css";

const Success = () => {
  return (
    <div style={{display:'flex',alignItems:"center",justifyContent:"center",height:"100vh"}}>
    <div class="card21">
     <div style={{borderRadius:'200px',height:'100px',width:"800px",display:"flex",alignItems:"center",justifyContent:"center"}}>
     <img
             src="/image.png"
             alt="Your Image"
             style={{
               width: "80%", // Adjust as needed
               height: "auto",
               alignSelf: "center",
             }}
           />
     </div>
       <h1 style={{color:'#000000'}}>Success</h1> 
       <p style={{textAlign:"center"}}>Your password has been successfully updated.<br/> </p>
     </div>
   </div>
  )
}

export default Success
