import React, { useEffect, useState, useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import "./App.css";
import { IconButton } from "@material-ui/core";
import Succes from "./succes";
import { useNavigate } from "react-router-dom";
import Modal from "./model";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "50%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  leftGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: theme.spacing(2), // Adjust as needed
  },
  rightGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: ""
  });
  const [isVisable, setIsVisable] = useState(true);
  const [isVisable1, setIsVisable1] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const classes = useStyles();
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(null);
  const [isViewOpen, setIsViewOpen] = useState(false);

  const closeView = () => {
    setIsViewOpen(false);
  };

  const openView = () => {
    setIsViewOpen(true);
    console.log(isViewOpen , 'hello')
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOnBlue = (event) => {
    // Handle onBlur events
  };

  const handleOnSubmit = (event) => {
  event.preventDefault();

  // Reset errors
  setErrors({});

  // Validation
  let validationErrors = {};

  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!formData.email.trim()) {
    validationErrors.email = "Email is required";
  } else if (!emailRegex.test(formData.email)) {
    validationErrors.email = "Invalid email format";
  }

  // If there are validation errors, set them in the state
  if (Object.keys(validationErrors).length > 0) {
    setErrors(validationErrors);
  } else {
    // Continue with your password reset logic
    setLoading(true);
    let body = {
      email: formData.email,
    };
   console.log("#########======>",body)
    axios
      .post(`https://api.codenomad.biz/send_forgot_pass_link`, body)
      .then((res) => {
        if (res.data.code === "200") {
            setLoading(false)
      
          openView();
          setTimeout(()=>{
            closeView()
            setFormData({ email: "" });
          },3000)
          // Use state variable for success message and display it in your component
          // setSuccessMessage("Email update successful");
          // Or navigate to the success page
        }
      })
      .catch((error) => {
        console.error("API error:", error)
      });
  }
};

  return (
    <Container
      // component="main"
      maxWidth="xl"
    >
      <div className='paper' style={{ width: "90%",display:'flex',alignSelf:'center',justifyContent:'center', margin:"auto",height:"93vh",borderRadius:20 }}>
        {/* {
         token ? "" : <Alert variant="filled" severity="success">
            This is a filled success Alert.
          </Alert>
        } */}
        <Grid container>
          {/* Left Grid - Image */}
          <Grid
            item
            xs={12}
            sm={6}
            className="leftSide"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:"#de2027",
              borderTopLeftRadius:20,
              borderBottomLeftRadius:20
            }}
          >
            <img
              src="/Group3.png"
              alt="Your Image"
              style={{
                width: "70%", // Adjust as needed
                height: "auto",
                // alignSelf: "center",
              }}
            />
          </Grid>

          {/* Right Grid - Form */}
          <Grid
            item
            xs={12}
            sm={6}
            className="rightSide"
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              height: "90vh",
              width: "60%",
              margin: "auto",
              paddingLeft: 60,
              backgroundColor:'#ffffff',
              borderTopRightRadius:20,
              borderBottomRightRadius:20
            }}
          >
               <img
              src="/image.png"
              alt="Your Image"
              style={{
                width: "70%", // Adjust as needed
                height: "auto",
                alignSelf: "center",
              }}
            />
            <Typography
              component="h3"
              variant="h5"
              style={{
                color: "#000000",
                fontSize: 40,
                fontWeight: "revert-layer",
                paddingTop:70
              }}
            >
             Forgot Password
            </Typography>
            {/* <Typography
              variant="h5"
              style={{ color: "#000000", fontSize: 18, paddingTop: 20 }}
            >
              Hi, {name}
            </Typography> */}
            {/* <Typography
              variant="h5"
              style={{ color: "#000000", fontSize: 16, paddingTop: 20 }}
            >
              Enter a new password below to Update your password.
            </Typography> */}
            <form className='formClass' noValidate onSubmit={handleOnSubmit}>
              <TextField
                className={classes.inputs}
                variant="outlined"
                type={"email"}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                onBlur={handleOnBlue}
                value={formData.email}
                error={!!errors.email}
                helperText={errors.email}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <IconButton onClick={() => setIsVisable(!isVisable)}>
                //         {!isVisable ? (
                //           <VisibilityOffIcon />
                //         ) : (
                //           <VisibilityIcon />
                //         )}
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  backgroundColor:"#de2027",
                  color: "#ffffff",
                  height: 50,
                }}
                className={classes.submit}
                // onClick={()=> handleOnSubmit()}
              >
                {loading ? (
                  // If loading is true, show the CircularProgress component
                  <CircularProgress size={24} style={{ color: "#ffffff" }} />
                ) : (
                  // If loading is false, show the button text ("Reset" in this case)
                  "Submit"
                )}
              </Button>

              {/* <Button
                type="button"
                fullWidth
                variant="contained"
                style={{
                  backgroundColor:"#de2027",
                  color: "#ffffff",
                  height: 50,
                }}
                className={classes.submit}
                onClick={()=> openView()}
              >
                open
              </Button> */}
            </form>
          </Grid>
        </Grid>
      </div>
      <Modal isOpen={isViewOpen} onClose={closeView}>
        <div className="model-head">
            <img src='/email.png'  alt="email" />
            <Typography
              component="h1"
              variant="h1"
              style={{
                color: "#000000",
                fontSize: 40,
                fontWeight: "revert-layer",
                paddingTop:20
              }}
            >Check your Email </Typography>
             <Typography
              component="p"
              variant="p"
              style={{
                color: "#000000",
                fontSize: 20,
                fontWeight: "revert-layer",
                paddingTop:20,
                paddingBottom:20
              }}
            >We emailed a magic link to {formData.email}</Typography>
        </div>
            
        </Modal>

      {/* <Succes/> */}
    </Container>
  );
};

export default ForgotPassword;
