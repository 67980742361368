import React,{useEffect} from 'react'
import "./App.css";
import {useLocation} from 'react-router-dom';
const Auth = () => {
    const location = useLocation();
    const {code,msg} = location.state;
  console.log("error page====>")
  return (
    <div style={{display:'flex',alignItems:"center",justifyContent:"center",height:"100vh"}}>
     <div class="card21">
      <div style={{borderRadius:'200px',height:'100px',width:"800px",margin:"0 auto",display:"flex",alignItems:"center",justifyContent:"center"}}>
      <img
              src="/image.png"
              alt="Your Image"
              style={{
                width: "80%", // Adjust as needed 
                height: "auto",
                alignSelf: "center",
              }}
            />
      </div>
      {
         code != '' ?  <h1 style={{color:'#000000'}}>{code}</h1>  : 
         <h1 style={{color:'#000000'}}>Welcome</h1>
      }
       
        <p style={{textAlign:"center"}}>{msg}<br/> </p>
      </div>
    </div>
  )
}

export default Auth
