import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla dapibus mauris in tellus dictum, nec egestas
        nulla lacinia. Donec euismod sem at nisl tincidunt, sed fermentum quam vehicula. Proin et vestibulum enim, eu
        tempor velit. Nulla vestibulum magna auctor justo consequat, vitae blandit dolor bibendum. Aenean ac tincidunt
        arcu. Cras pharetra dui vel odio volutpat hendrerit. Proin nec mauris sed libero posuere sodales. Quisque
        gravida, tortor vel aliquam feugiat, lorem nisl laoreet elit, eget venenatis nibh libero in lacus. Aliquam
        congue odio non enim sollicitudin blandit.
      </p>
      <p>
        Fusce tincidunt ultricies arcu, in interdum orci convallis sed. Proin eu scelerisque erat. Nullam quis risus
        sed sem tincidunt sodales. Nulla fringilla orci nec enim tempor, in accumsan libero commodo. Maecenas pharetra
        commodo tincidunt. Donec et urna feugiat, sagittis quam sed, tristique risus. Maecenas eget massa nec nulla
        bibendum tempus. Vivamus tincidunt, odio non convallis interdum, felis turpis ultrices velit, ut laoreet purus
        neque at sapien.
      </p>
      <p>
        Integer pretium enim eu massa faucibus, eu elementum risus placerat. Nam bibendum ipsum non nisi fringilla,
        vel hendrerit ipsum vestibulum. Aliquam hendrerit libero at nunc condimentum, vitae congue ex efficitur. Nulla
        facilisi. Aenean pharetra mi vel metus efficitur lobortis. Duis nec congue est. Fusce sed dictum arcu. Donec
        scelerisque laoreet massa, ac fermentum purus tristique a. Curabitur auctor laoreet libero, ac venenatis
        mauris convallis vitae. Nullam et tempor nisl.
      </p>
      <p>
        Praesent rutrum vestibulum quam, sed dapibus sapien suscipit quis. Nullam consequat dui eget neque dapibus,
        sed pellentesque magna dictum. Aenean vel est vitae arcu tincidunt pellentesque. Proin aliquet ipsum non sem
        tempus, sit amet efficitur quam tempus. Ut nec tellus sed ante lobortis fermentum. Mauris vel consectetur
        mauris. Ut interdum lorem ac tincidunt elementum.
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;